<template>
    <div>
        <div v-if="isOrdersModuleAvailable()" :class="['tab-pane', currentTab === 'import-orders' ? 'active show' : '']">
            <import-orders v-if="currentTab === 'import-orders'" />
        </div>
    </div>
</template>

<script>
import ImportOrders from '@/components/Orders/ImportOrders.vue'
import Features from '@/services/Features'
import { FeatureOrders } from '@/constants'
import Access from '@/services/Access'

export default {
    name: 'settings.profile',
    components: {
        ImportOrders,
    },
    data() {
        return {
            currentTab: 'import-orders',
        }
    },
    methods: {
        isOrdersModuleAvailable() {
            return Features.checkFeature(FeatureOrders)
                && Access.checkAccess('orders', Access.LEVEL_READ)
        },
    },
}
</script>
