<template>
    <div>
        <b-card
            title="Upload csv file"
        >
            <b-form>
                <b-form-row v-if="errors.length > 0">
                    <b-col md-12>
                        <div class="alert alert-danger py-2 pl-2">
                            <ul style="list-style: none;padding: 0;margin: 0;">
                                <li v-for="error in errors">
                                    {{ error }}
                                </li>
                            </ul>
                        </div>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-col md="6" offset-md="1">
                        <b-form-group
                            label="File (.csv)"
                            label-for="s-file"
                            label-cols-md="4"
                        >
                            <b-form-file
                                id="s-file"
                                v-model="file"
                                accept=".csv"
                                placeholder="Choose a file or drag and drop it here..."
                            />
                            <br>
                            <span
                                class="small"
                            >
                                Please upload a CSV file. After uploading you will be able to map fields in the CSV file with fields in the orders.
                            </span>
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <hr class="mt-2 mb-2">
                <b-form-row>
                    <b-col md="3">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-1"
                            :disabled="loading"
                            @click="submitCsvFile"
                        >
                            <b-spinner v-if="loading" small />
                            Upload
                        </b-button>
                    </b-col>
                </b-form-row>

            </b-form>
        </b-card>

        <b-card
            v-if="hasUploadedFile"
            title="Import uploaded order file"
        >
            <b-form>
                <b-form-row v-if="orderImported">
                    <b-col md-12>
                        <div class="alert alert-success py-2 pl-2">
                            Orders imported
                        </div>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-col md="12">
                        <span>
                            Currently showing mapping for file:
                            <strong>{{ uploadedFileName }}</strong>
                        </span>
                        <template v-for="column in columnMapping">
                            <div class="row form-group m-form__group">
                                <div class="col-3 offset-2">
                                    <label class="col-form-label">
                                        Column: <strong>{{ column.column }}</strong>
                                    </label>
                                </div>
                                <div class="col-2 text-right">
                                    <label class="col-form-label">
                                        Maps to:
                                    </label>
                                </div>
                                <div class="col-4">
                                    <select v-model="column.mappedTo" class="form-control"
                                            :class="column.saving ? 'import_mapping__select--saving' : ''"
                                            @change="tryToSaveMapping()"
                                    >
                                        <option value="">
                                            None selected
                                        </option>
                                        <option v-for="field in defaultFields" :value="field.externalKey">
                                            {{ field.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </template>
                    </b-col>
                </b-form-row>
                <hr class="mt-2 mb-2">
                <b-form-row>
                    <b-col md="6">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-1"
                            :disabled="loading"
                            @click="importOrder()"
                        >
                            <b-spinner v-if="loading" small />
                            Import order
                        </b-button>
                    </b-col>
                </b-form-row>

            </b-form>
        </b-card>
    </div>
</template>

<style>
</style>
<script>
import Axios from 'axios'
import {
    BCol,
    BFormFile,
    BForm,
    BFormGroup,
    BFormRow,
    BButton,
    BCard,
    BSpinner,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BCol,
        BFormFile,
        BFormGroup,
        BFormRow,
        BForm,
        BButton,
        BCard,
        BSpinner,
    },
    directives: {
        Ripple
    },
    data() {
        return {
            loading: false,
            fileUploaded: false,
            uploadedFileName: '',
            hasUploadedFile: false,
            columnMapping: [],
            defaultFields: [],
            canSaveMapping: false,
            saveMappingTimeout: null,
            currentlySavingColumn: null,
            orderImported: false,
            errors: [],
            fileData: {
                file: '',
                fileName: '',
            },
            file: null,
            file_2: null,
        }
    },
    watch: {
        async file(newFile) {
            await this.filePicked(newFile)
        }
    },
    created() {
        this.loadExistingFile()
    },
    methods: {
        async loadExistingFile() {
            this.loading = true
            this.canSaveMapping = false
            this.hasUploadedFile = false
            const resp = await Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}project/orders/get-order-import-data`,
                method: 'GET',
            })
            this.columnMapping = resp.data.columns
            this.addSavingPropertyToColumns()
            this.defaultFields = resp.data.availableFields
            this.uploadedFileName = resp.data.fileName
            this.loading = false
            this.canSaveMapping = true
            if (this.columnMapping && this.uploadedFileName) {
                this.hasUploadedFile = true
            }
        },
        submitCsvFile() {
            this.loading = true
            this.orderImported = false
            this.fileUploaded = false
            this.errors = []
            const data = {
                file: this.fileData.file,
                fileName: this.fileData.fileName,
            }
            setTimeout(() => {
                Axios({
                    url: `${process.env.VUE_APP_API_HTTP_ROOT}project/orders/upload-order-import-csv`,
                    data,
                    method: 'POST',
                })
                    .then(() => {
                        this.loading = false
                        this.loadExistingFile()
                    })
                    .catch(err => {
                        for (const errorGroupKey of Object.keys(err.response.data.errors)) {
                            const errorGroup = err.response.data.errors[errorGroupKey]
                            this.errors = this.errors.concat(errorGroup)
                        }

                        this.loading = false
                    })
            }, 1000)
        },
        async filePicked(fileInfo) {
            return new Promise(resolve => {
                const file = fileInfo
                if (file) {
                    this.fileData.fileName = file.name
                    this.getBase64(file).then(
                        data => {
                            this.fileData.file = data
                            this.$forceUpdate()
                            resolve()
                        },
                    )
                }
            })
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve(reader.result.split(',').pop())
                reader.onerror = error => reject(error)
            })
        },
        tryToSaveMapping() {
            if (!this.canSaveMapping) {
                return
            }
            if (this.saveMappingTimeout) {
                clearTimeout(this.saveMappingTimeout)
            }
            this.saveMappingTimeout = setTimeout(async () => {
                this.markAllColumnSavingStatus(true)
                this.orderImported = false
                this.loading = true
                await this.saveMapping()
                this.loading = false
                this.markAllColumnSavingStatus(false)
            }, 100)
        },
        async saveMapping() {
            await Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}project/orders/set-order-import-mapping`,
                data: {mapping: this.columnMapping},
                method: 'POST',
            })
        },
        addSavingPropertyToColumns() {
            this.markAllColumnSavingStatus(false)
        },
        markAllColumnSavingStatus(status) {
            this.columnMapping = this.columnMapping.map(mapping => {
                const returnMapping = mapping
                returnMapping.saving = status

                return returnMapping
            })
        },
        async importOrder() {
            this.loading = true
            this.orderImported = false
            await this.saveMapping()
            await Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}project/orders/import-order`,
                method: 'GET',
            })
            this.loading = false
            this.orderImported = true
        },
    },
}
</script>
